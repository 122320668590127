import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
    {
        path: '/login',
        component: () => import('@/views/login/LoginPage')
    },
    {
        path: '/register',
        component: () => import('@/views/login/RegisterPage')
    },
    {
        path: '/reset',
        component: () => import('@/views/login/ResetPage')
    },
    {
        path: '/',
        redirect: '/dashboard',
        component: () => import('@/views/home/index'),
        children: [
            {
                path: '/dashboard',
                component: () => import('@/views/dashboard')
            },
            {
                path: '/billing',
                component: () => import('@/views/billing')
            },
            {
                path: '/transaction',
                component: () => import('@/views/transation')
            },
            {
                path: '/meter-data',
                component: () => import('@/views/meterData')
            },
            {
                path: '/account',
                component: () => import('@/views/account')
            },
        ]
    }
]
export default new VueRouter({
    routes 
})