import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/antd.css'
import router from './router/router'
import '@/assets/common.css'
import '@/assets/font/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss' // global css
import { DatePicker } from 'element-ui'
import plugins from '@/utils/plugins'
import { getToken } from '@/utils/auth'
Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(plugins)
Vue.use(DatePicker)
const whitePath = ['/login','/register', '/reset']
router.beforeEach((to, from, next) => {
  document.title = to.meta?.title || 'ATLAS'
  if (getToken()) {
    if (to.path === '/login') {
      next({path: '/'})
    } else {
      next()
    }
  } else {
    if (whitePath.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')