<template>
  <div class="wrap">
      <div class="box">
        <div class="title flex">
            <span>{{title}}</span>
            <img src="@/assets/imgs/Iconclose.png" class="close-icon cursor-point" alt="" @click="close">
        </div>
        <div class="content">
            <slot></slot>
        </div>
        <slot name="btn" v-if="isNeedBtn">
            <button class="btn cursor-point" @click="confirm">Login</button>
        </slot>
        <slot name="footer"></slot>
      </div>
  </div>
</template>

<script>
export default {
    name: 'dialog-component',
    props: {
        title: {
            type: String,
            default: ''
        },
        isNeedBtn: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        confirm() {
            this.$emit('confirm')
        }
    }
}
</script>

<style scoped>
    .wrap {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: rgba(0, 0, 0, .4);
    }
    .box {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%,-50%);
        width: 400px;
        padding: 24px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
        border-radius: 12px; 
    }
    .close-icon {
        width: 12px;
        height: 12px;
    }
    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #101828;
        justify-content: space-between;
        align-items: center;
    }
    .content {
        color: #475467;
        line-height: 20px;
        margin-top: 10px;
    }
    .btn {
        background-color: #155EEF;
        color: #fff;
        width: 100%;
        height: 44px;
        border: 1px solid #155EEF;
        font-size: 16px;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        margin-top: 30px;
    }
</style>