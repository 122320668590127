<template>
  <div class="btn-wrap">
    <span><slot></slot></span>
  </div>
</template>

<script>
export default {
    name: 'basic-btn',
    props: {
        
    }
}
</script>

<style scoped>
    .btn-wrap {
        display: inline-block;
        padding: 8px 14px;
        background: #0040C1;
        /* Primary/800 */
        border: 1px solid #0040C1;
        /* Shadow/xs */
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
        text-align: center;
    }
</style>